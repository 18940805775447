import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../../components/Layout'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Spacing from '../../components/partials/Spacing'
import AboveFooterSection from '../../components/AboveFooterSection'
import { StaticImage } from 'gatsby-plugin-image'

const marineTraffic = () => {
  const data = useStaticQuery(graphql`
    query MarineTrafficSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "MarineTraffic" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="overflow-hidden hero-margin">
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-lg-4 col-xl-5 col-12 justify-content-center align-self-center">
              <div className="mb-3">
                <img
                  className="w-30 pb-3"
                  src="//images.ctfassets.net/tnuaj0t7r912/6y3urY2YE7Md2yhYJYxZRW/53732bdab2ae83ab9a02f98d6eb81bf5/MT_Square_BG-Transparent_white-blue__1___1_.png?w=200&q=100"
                  alt="MarineTraffic Logo"
                />
              </div>
              <div>
                <h1>
                  {' '}
                  MarineTraffic maps shipping fleet data with Apache Kafka
                </h1>
                <div>
                  <h2 className="hero-description-dark pt-1 roboto">
                    To stay competitive, MarineTraffic live vessel intelligence
                    required a shift from batch to streaming data.
                  </h2>
                </div>
              </div>

              <div className="roll-button mt-3">
                <a className="text-white bg-red" href="/start/">
                  <span id="heroButton" data-hover="Try free">
                    Try free
                  </span>
                </a>
              </div>
            </div>

            <div className="col-lg-8 col-xl-7 col-12 tablet-margin-top">
              <img
                src="//images.ctfassets.net/tnuaj0t7r912/5P5gk935cBImPMlFbleANf/baedf6c21e248163ecbc8d307b91ced1/MarineTraffic_Hero.png?w=550&q=100"
                className="img-fluid d-block ml-auto"
                alt="MarineTraffic Hero"
              />
            </div>
          </div>
        </div>
      </section>
      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-md-3 col-sm-12 mobile-margin-bottom mobile-margin-top">
              <div className="footer-color position-sticky rounded casestudy-container-left">
                <div className="px-3 py-1">
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    +50%
                  </p>
                  <p>
                    increase in <b>productivity.</b>
                  </p>
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    10-12 developers
                  </p>
                  <p>
                    logging in <b>daily</b>
                  </p>

                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    3x faster
                  </p>
                  <p>
                    than <b> building</b> their own tools.
                  </p>
                </div>
                <div className="d-flex flex-wrap">
                  <div className="col-4 col-md-6 col-sm-3 ">
                    <img
                      src="//images.ctfassets.net/tnuaj0t7r912/7wqYfkcxMIkQDiTfAXimeI/0edeff0d7823e7ec040bd0168168c66b/aiven-logo_vert_RGB__1_.png"
                      alt="Aiven"
                      className="w-100 py-2"
                    />
                  </div>
                  <div className="col-4 col-md-6 col-sm-3 offset-sm-1 offset-md-0">
                    <img
                      src="//images.ctfassets.net/tnuaj0t7r912/JxmkcPAo31A1L8Q2zAzBs/93c283b05e719113aec27baeecfad43d/elastic_vertical.svg"
                      alt="Elastic search"
                      className="w-100 py-1"
                    />
                  </div>
                  <div className="col-4 col-md-6 col-sm-3 offset-sm-1 offset-md-0">
                    <img
                      src="//images.ctfassets.net/tnuaj0t7r912/1xKb2xbIXADSban6Klig3x/b31776d450060dbe1a893677864f17f0/salesforce.svg"
                      alt="Salesforce"
                      className="w-100 py-2"
                    />
                  </div>
                </div>
                <div className="bg-white rounded bio-author-space"></div>
                <div className="d-flex flex-wrap align-items-center justify-content-start">
                  <div className="px-3 py-1 my-4">
                    <img
                      className="author-photo mx-auto gatsby-image-wrapper img-60"
                      src="//images.ctfassets.net/tnuaj0t7r912/40820gRXglUZCh1vDskZFV/14f19a9e941c770ff3b71bd56a93abf1/christos-marinetraffic__1_.jpg"
                      alt="Christos Mantzouranis"
                    />
                  </div>
                  <div className="f-12">
                    <b>
                      Christos <br /> Mantzouranis
                    </b>
                    <b>
                      <div className="f-12 primary-text">
                        Back-end <br /> Team Leader
                      </div>
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12">
              <div className="casestudy-container-right">
                <p className="p-text">
                  <b>
                    To achieve this, they enabled their platform team with
                    Lenses.io - an easy, fast way to query real-time data on
                    Kafka.
                  </b>
                </p>

                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-20 quote-line-height">
                    <i>
                      The Lenses.io portal helps us to harness all of Kafka’s
                      powerful complexity and make it more available and
                      actionable for us. It would have taken us 3x as long to
                      develop our own tools with tape and sticks - so working on
                      Lenses.io helped us move much faster to fulfil analytics
                      requirements. It has also been much easier to onboard
                      newcomers to Kafka. The technology seems infinitely less
                      intimidating which means that more team members can master
                      its benefits.
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>Christos Mantzouranis, Back-end Team Leader</i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>

                <p className="h3-like-dark mb-1 mt-5 text-dark">
                  “It didn’t end well for Kafka the author, so watch out.”
                </p>
                <p className="p-text">
                  MarineTraffic helps the marine industry to track vessel
                  positions. Real-time data is a mission-critical propeller for
                  all their products - from predictive models for fuel
                  consumption and changing ship routes, to live maps for
                  customers to view their fleet in relation to the weather.
                </p>
                <p className="p-text">
                  This was why the Engineering team decided to switch to Kafka
                  for its decentralization and horizontal scalability. The team
                  was partway in a necessary transition from self-hosted
                  bare-metal infrastructure to the cloud. This involved
                  rebuilding their monolithic .NET, PHP and Node.JS application
                  to a new cloud-native microservice architecture based on
                  streaming data. The team&apos;s bigger goal was to make
                  smaller deployments on cloud services like Amazon and migrate
                  from self-hosted Kafka to Kafka-as-a-service with AIVEN.
                </p>
                <p className="p-text">
                  But although Christos and his team wanted to get
                  better-acquainted with Kafka’s complexities, the resources
                  required to make the data accessible and comprehensible across
                  analytics and product teams was too big of an ask.
                </p>

                <p className="h3-like-dark mb-1 mt-5 text-dark">
                  From batch to stream, sink or swim?
                </p>

                <p className="p-text">
                  <i>
                    “We needed connectors and ACLs - an easy way to query data
                    in Kafka across the company without investing the manpower
                    and hours needed, and with Lenses.io, these needs were met.”
                  </i>
                </p>
                <p className="p-text">
                  <a
                    className="link-text"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="//www.marinetraffic.com/"
                  >
                    MarineTraffic{' '}
                  </a>
                  uses
                  <b> Lenses.io</b> to achieve the following overview for their
                  real-time data:
                </p>

                <ul className="red-checked-list mobile-padding-reset">
                  <li>
                    <span className="pl-1">
                      <b>Visibility of data flows to get production-ready: </b>
                      providing a full overview of the health and security of
                      applications
                    </span>
                  </li>
                  <li>
                    <span className="pl-1">
                      <b>Setting alerts:</b> tracking consumer lag and
                      configuring alert conditions allows the team to give the
                      right critical information to the right data consumers
                    </span>
                  </li>
                  <li>
                    <span className="pl-1">
                      <b>Easily creating topics, ACLs & Quotas: </b>
                      viewing, creating, edit or deleting from a UI or API with
                      full role-based access controls and audits.
                    </span>
                  </li>
                </ul>

                <p className="h3-like-dark mb-1 mt-5 text-dark">
                  Simplifying Kafka commands with SQL has increased productivity
                  by 50%
                </p>
                <p className="p-text">
                  <i>
                    “We now have the capacity to ask everything with Kafka; to
                    find messages which were discarded. We are using LSQL to
                    query our data which is very familiar to both our software
                    and data engineers. Also, the ease of set-up for the various
                    connectors has allowed fast ETLs.
                  </i>
                </p>

                <p className="p-text">
                  Now <b> 10-12 developers log in every day.</b>
                </p>

                <p className="p-text">
                  Being able to horizontally process messages allows us to
                  generate notifications that would otherwise be complex to
                  calculate. With Lenses.io on Kafka, we can easily monitor the
                  topology and scale up when needed.
                </p>

                <p className="p-text">
                  <b>It’s increased our productivity by 50%.</b>
                </p>
                <p className="p-text">
                  <i>
                    If it weren’t for Lenses.io the decentralization process
                    would have been a real struggle. Keeping a monolith running
                    on a single humongous server is a huge investment and a
                    constant liability.”
                  </i>
                  explains Christos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'The Data Platform for Open Source technologies'}
        ctaText={'Start'}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default marineTraffic
